@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  --darkBorder: #b2b2b2;

  --gray: #f2f2f2;
  --borderGray: #DDDDDD;
  --placeholderGray: #AAAAAA;
  --darkerGray: #333333;
  --lightGray: #F0F0F0;
  --mediumGray: #979797;
  --blue: #1400ff;

  --primaryColor: var(--blue);
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

button.default {
  background-color: rgba(0,0,0,0);
  border:none;
}

.explanationText{
	background-color: #f8f8fc;
	border: 1px solid #dadada;
	font-size: 0.75rem;
	font-weight: 300;
	padding: 16px;
	color: #333333;
	line-height: 1.1rem;
	border-radius: 1px;
	text-align: left;
}
.mb-5{
	margin-bottom: 5rem !important;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
     -moz-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes App-logo-spin {
  from { -moz-transform: rotate(0deg); transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

.Icon-container {
  display: inline-block;
}

.Icon-container.circle {
  color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0,0,0,0.55);
  background-color: var(--primaryColor);
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
  line-height: inherit;
  text-align: center;
  padding: 5px;
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-align: center;
       align-items: center;
}

.Button {
  background: var(--gray);
  border: 1px solid var(--darkBorder);
  color: black;
  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 0.2rem;
  min-width: 100px;
  padding: 12px;
  text-transform: uppercase;
}

.Button:focus {
  outline-color: var(--primaryColor);
}

.Button-compact {
  min-width: 0;
}

.Button-primary {
  background: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  color: #ffffff;
  text-transform: capitalize;
  letter-spacing: 0.05rem;
  font-size: .85rem;
}

.Button-secondary {
    background: #ffffff;
    border: 2px solid var(--primaryColor);
    color: #1400ff;
    text-transform: capitalize;
    letter-spacing: 0.05rem;
    font-size: .85rem;
}

.Button-fluid {
  width: 100%;
  border-radius: 4px;
}

.Button-disabled {
  background-color: #5041ff;
}

.Button-basic {
  background: white;
  border-width: 3px;
  padding: 10px;
  color: var(--primaryColor);
}

.Button-basic.Button-secondary {
  color: black;
}

.Button-half {
  width:50%
}

.Testimonial {
  background-color: #FFFFFF;
  color: #5A5A5A;
  border-radius: 2px;
  margin: 10px 8px 0px 8px;
  padding: 30px 26px 33px 26px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.Testimonial-separator {
  margin-top: 17px;
  margin-bottom: 14px;
  font-weight: bold;
  border-bottom: 4px solid #5A5A5A;
  width: 16px;
  margin-right: auto;
  margin-left: auto;
}

.Testimonial-text {
  margin-bottom: 8px;
}

.Testimonial-name {
  font-style: italic;
}

.Testimonial img {
  width: 100px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0px 3px 8px 2px rgba(184,184,184,1);
}

.PartnerPicture {
  -moz-box-flex: 1;
       flex: 1 1;
  margin: 3vw;
  width: 20vw;
  text-align: center;
}

.PartnerPicture img {
  width: 90px;
}

.LandingPage {
  text-align: left;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  line-height: 1.5;
  overflow-y: scroll;
  /* scroll-snap-type: y proximity; */
  height: 100vh;
}

.LandingPage .Section {
  /* scroll-snap-align: start; */
}

.LandingPage .Intro {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  min-height: 100vh;
}

.LandingPage .IntroTitle {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-align: center;
       align-items: center;
  color: var(--primaryColor);
  font-size: 28px;
  font-weight: bold;
}

.LandingPage .IntroTitle > img {
  margin-right: 5px;
  width: 1.2em;
  height: 1.2em;
}

.LandingPage .IntroBody {
  -moz-box-flex: 1;
       flex: 1 1;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  justify-content: space-around ;
  -moz-box-align: center;
       align-items: center;
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),url('/img/landing_page/top1_desktop.jpg') no-repeat center center fixed;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),url('/img/landing_page/top1_desktop.jpg') no-repeat center center fixed;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),url('/img/landing_page/top1_desktop.jpg') no-repeat center center fixed;
  background-position: 30% 0%;
  background-size: cover;
  color: #FFFFFF;
}

.LandingPage .IntroBody > .IntroTop > h1 {
  padding-bottom: 24px;
  margin-left: 36px;
}

.LandingPage .IntroBody > .IntroTop > p {
  font-size: 21px;
}

.LandingPage .IntroBody > .IntroTop > .arrow {
  text-align: center;
}

.LandingPage .IntroBody > .IntroBottom {
  width: 100%;
  text-align: center;
}

.LandingPage .Onboarding .Section {
  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  padding-top: 5vh;
  overflow: hidden;
}

.LandingPage .Onboarding .Section:not(:last-child) {
  height: 100vh;
}

.LandingPage .Onboarding .Section .OnboardingText {
  min-height: 25vh;
  -moz-box-flex: 0;
       flex: 0 0 auto;
}

.LandingPage .Onboarding .Section .OnboardingImage {
  -moz-box-flex: 1;
       flex: 1 1;
  max-height: 70vh;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}

.step1 {
  -o-object-position: bottom;
     object-position: bottom;
}

.LandingPage .Onboarding .Section picture {
  -moz-box-flex: 1;
       flex: 1 1;
  max-height: 70vh;
}

.LandingPage .Onboarding .Section .OnboardingSectionNumber {
  position: absolute;
  z-index: -1;
  top: -10px;
  font-size: 42vh;
  line-height: 80%;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.12);
}

.LandingPage .Onboarding .Section:nth-child(odd) .OnboardingSectionNumber {
  right: 0px;
}

.LandingPage .Onboarding .Section:nth-child(even) .OnboardingSectionNumber {
  left: 0px;
}

.LandingPage .Testimonials {
  padding-top: 46px;
  padding-bottom: 42px;
  background-color: var(--primaryColor);
  color: #FFFFFF;
}

.TestimonialBtn {
  margin-top: 36px;
}

.LandingPage .TestimonialsInner {
  margin-bottom: 24px;
}

.LandingPage .Testimonials .TestimonialsTitle {
  margin-bottom: 5px;
}

.LandingPage .Partners {
  margin-top: 28px;
  margin-bottom: 50px;
}

.LandingPage .Partners .PartnerPictures {
  margin: 0px 36px 24px 36px;
  display: -moz-box;
  display: flex;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
       flex-direction: row;
  flex-wrap: wrap;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
}

.LandingPage .AboutUs {
  background-color: #1400ff;
  padding: 45px 32px;
  color: #fff;
  line-height: 24px;
}

.LandingPage .AboutUs .about-logo {
  display: -moz-box;
  display: flex;
  margin: 45px auto 0 auto;
  width: 145px;
}

.LandingPage .Feedback {
  background-color: #000000;
  color: #FFFFFF;
  padding: 45px 32px;
  text-align: left;
}

.LandingPage .Feedback .SectionInner h2 {
  margin-bottom: 6px;
}

.LandingPage .Feedback .SectionInner p {
  margin-bottom: 16px;
}

.LandingPage .Feedback .FeedbackEmail {
  background-color: #FFFFFF;
  margin-left: 36px;
  margin-right: 36px;
  height: 44px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
}

.LandingPage .Feedback .SocialIcons {
  margin: 0px 36px 46px;
  font-size: 1.1rem;
}

.LandingPage .Feedback .SocialIcons a {
  color: #FFFFFF;
  padding-right: 8px;
}

.LandingPage h1 {
  font-weight: bold;
  margin-left: 25px;
  margin-right: 25px;
}

.LandingPage h2 {
  font-weight: bold;
  margin: 0px 36px 24px 36px;
}

.LandingPage h3 {
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0px 36px 24px 36px;
}

.LandingPage p {
  font-size: 15px;
  margin: 0px 36px 24px 36px;
}

.LandingPage .text-lighter {
  color: #5A5A5A
}

.LandingPage .Emphasis {
  text-decoration: underline;
  font-style: italic;
}

.LandingPage .covidInfo {
  color: #fff;
  background: #0000FF;
  text-align: center;
  padding: 8px 0;
}

.covidModalInner {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  -moz-box-pack: justify;
       justify-content: space-between;
  height: 50vh;
}

.covidModalInner .legal {
  margin-top: 12px;
}

.GetStartedButton {
  padding: 0px 10px 0px 10px;
  width: 100%;
  margin-bottom: 15px;
}

.GetStartedButton button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
  font-size: 17px;
  height: 44px;
  line-height: normal;
  padding: 0px;
  text-transform: none;
}

.GetStartedButton .btnWhite {
  background-color: white;
  color: #1400ff;
}

.arrowUp {
  margin-top: 74px;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  -moz-box-align: center;
       align-items: center;
}

@media screen and (max-width: 470px) {
  .LandingPage {
    font-size: 15px;
  }

  .LandingPage h1 {
    font-size: 30px;
  }

  .LandingPage h2 {
    font-size: 20px;
  }

  .LandingPage h3 {
    font-size: 11px;
  }
}

@media screen and (max-width: 1224px) {
  .LandingPage .IntroBody {
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),url('/img/landing_page/top1_mobile.jpg') no-repeat center center fixed;
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),url('/img/landing_page/top1_mobile.jpg') no-repeat center center fixed;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),url('/img/landing_page/top1_mobile.jpg') no-repeat center center fixed;
  }
}

@media screen and (min-width: 1224px) {
  .IntroTop, .IntroBottom {
    max-width: 800px;
  }

  .TestimonialsInner, .TestimonialBtn, .SectionInner, .OnboardingText {
    max-width: 800px;
    margin: 0 auto;
  }
}

