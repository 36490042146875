.Icon-container {
  display: inline-block;
}

.Icon-container.circle {
  color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0,0,0,0.55);
  background-color: var(--primaryColor);
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
  line-height: inherit;
  text-align: center;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
