.PartnerPicture {
  flex: 1 1 0px;
  margin: 3vw;
  width: 20vw;
  text-align: center;
}

.PartnerPicture img {
  width: 90px;
}
