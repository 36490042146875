.Button {
  background: var(--gray);
  border: 1px solid var(--darkBorder);
  color: black;
  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 0.2rem;
  min-width: 100px;
  padding: 12px;
  text-transform: uppercase;
}

.Button:focus {
  outline-color: var(--primaryColor);
}

.Button-compact {
  min-width: 0;
}

.Button-primary {
  background: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  color: #ffffff;
  text-transform: capitalize;
  letter-spacing: 0.05rem;
  font-size: .85rem;
}

.Button-secondary {
    background: #ffffff;
    border: 2px solid var(--primaryColor);
    color: #1400ff;
    text-transform: capitalize;
    letter-spacing: 0.05rem;
    font-size: .85rem;
}

.Button-fluid {
  width: 100%;
  border-radius: 4px;
}

.Button-disabled {
  background-color: #5041ff;
}

.Button-basic {
  background: white;
  border-width: 3px;
  padding: 10px;
  color: var(--primaryColor);
}

.Button-basic.Button-secondary {
  color: black;
}

.Button-half {
  width:50%
}
