@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);

.LandingPage {
  text-align: left;
  box-sizing: border-box;
  line-height: 1.5;
  overflow-y: scroll;
  /* scroll-snap-type: y proximity; */
  height: 100vh;
}

.LandingPage .Section {
  /* scroll-snap-align: start; */
}

.LandingPage .Intro {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.LandingPage .IntroTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primaryColor);
  font-size: 28px;
  font-weight: bold;
}

.LandingPage .IntroTitle > img {
  margin-right: 5px;
  width: 1.2em;
  height: 1.2em;
}

.LandingPage .IntroBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around ;
  align-items: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),url('/img/landing_page/top1_desktop.jpg') no-repeat center center fixed;
  background-position: 30% 0%;
  background-size: cover;
  color: #FFFFFF;
}

.LandingPage .IntroBody > .IntroTop > h1 {
  padding-bottom: 24px;
  margin-left: 36px;
}

.LandingPage .IntroBody > .IntroTop > p {
  font-size: 21px;
}

.LandingPage .IntroBody > .IntroTop > .arrow {
  text-align: center;
}

.LandingPage .IntroBody > .IntroBottom {
  width: 100%;
  text-align: center;
}

.LandingPage .Onboarding .Section {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 5vh;
  overflow: hidden;
}

.LandingPage .Onboarding .Section:not(:last-child) {
  height: 100vh;
}

.LandingPage .Onboarding .Section .OnboardingText {
  min-height: 25vh;
  flex: 0 0 auto;
}

.LandingPage .Onboarding .Section .OnboardingImage {
  flex: 1;
  max-height: 70vh;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.step1 {
  object-position: bottom;
}

.LandingPage .Onboarding .Section picture {
  flex: 1;
  max-height: 70vh;
}

.LandingPage .Onboarding .Section .OnboardingSectionNumber {
  position: absolute;
  z-index: -1;
  top: -10px;
  font-size: 42vh;
  line-height: 80%;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.12);
}

.LandingPage .Onboarding .Section:nth-child(odd) .OnboardingSectionNumber {
  right: 0px;
}

.LandingPage .Onboarding .Section:nth-child(even) .OnboardingSectionNumber {
  left: 0px;
}

.LandingPage .Testimonials {
  padding-top: 46px;
  padding-bottom: 42px;
  background-color: var(--primaryColor);
  color: #FFFFFF;
}

.TestimonialBtn {
  margin-top: 36px;
}

.LandingPage .TestimonialsInner {
  margin-bottom: 24px;
}

.LandingPage .Testimonials .TestimonialsTitle {
  margin-bottom: 5px;
}

.LandingPage .Partners {
  margin-top: 28px;
  margin-bottom: 50px;
}

.LandingPage .Partners .PartnerPictures {
  margin: 0px 36px 24px 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.LandingPage .AboutUs {
  background-color: #1400ff;
  padding: 45px 32px;
  color: #fff;
  line-height: 24px;
}

.LandingPage .AboutUs .about-logo {
  display: flex;
  margin: 45px auto 0 auto;
  width: 145px;
}

.LandingPage .Feedback {
  background-color: #000000;
  color: #FFFFFF;
  padding: 45px 32px;
  text-align: left;
}

.LandingPage .Feedback .SectionInner h2 {
  margin-bottom: 6px;
}

.LandingPage .Feedback .SectionInner p {
  margin-bottom: 16px;
}

.LandingPage .Feedback .FeedbackEmail {
  background-color: #FFFFFF;
  margin-left: 36px;
  margin-right: 36px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LandingPage .Feedback .SocialIcons {
  margin: 0px 36px 46px;
  font-size: 1.1rem;
}

.LandingPage .Feedback .SocialIcons a {
  color: #FFFFFF;
  padding-right: 8px;
}

.LandingPage h1 {
  font-weight: bold;
  margin-left: 25px;
  margin-right: 25px;
}

.LandingPage h2 {
  font-weight: bold;
  margin: 0px 36px 24px 36px;
}

.LandingPage h3 {
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0px 36px 24px 36px;
}

.LandingPage p {
  font-size: 15px;
  margin: 0px 36px 24px 36px;
}

.LandingPage .text-lighter {
  color: #5A5A5A
}

.LandingPage .Emphasis {
  text-decoration: underline;
  font-style: italic;
}

.LandingPage .covidInfo {
  color: #fff;
  background: #0000FF;
  text-align: center;
  padding: 8px 0;
}

.covidModalInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50vh;
}

.covidModalInner .legal {
  margin-top: 12px;
}

.GetStartedButton {
  padding: 0px 10px 0px 10px;
  width: 100%;
  margin-bottom: 15px;
}

.GetStartedButton button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
  font-size: 17px;
  height: 44px;
  line-height: normal;
  padding: 0px;
  text-transform: none;
}

.GetStartedButton .btnWhite {
  background-color: white;
  color: #1400ff;
}

.arrowUp {
  margin-top: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 470px) {
  .LandingPage {
    font-size: 15px;
  }

  .LandingPage h1 {
    font-size: 30px;
  }

  .LandingPage h2 {
    font-size: 20px;
  }

  .LandingPage h3 {
    font-size: 11px;
  }
}

@media screen and (max-width: 1224px) {
  .LandingPage .IntroBody {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),url('/img/landing_page/top1_mobile.jpg') no-repeat center center fixed;
  }
}

@media screen and (min-width: 1224px) {
  .IntroTop, .IntroBottom {
    max-width: 800px;
  }

  .TestimonialsInner, .TestimonialBtn, .SectionInner, .OnboardingText {
    max-width: 800px;
    margin: 0 auto;
  }
}
