body {
  --darkBorder: #b2b2b2;

  --gray: #f2f2f2;
  --borderGray: #DDDDDD;
  --placeholderGray: #AAAAAA;
  --darkerGray: #333333;
  --lightGray: #F0F0F0;
  --mediumGray: #979797;
  --blue: #1400ff;

  --primaryColor: var(--blue);
}
